export const headerItems = [
  {
    title: "Biography",
    slug: "biography",
  },
  {
    title: "Weddings",
    slug: "weddings",
  },
  {
    title: "Listen",
    slug: "listen",
  },
  {
    title: "Testimonials",
    slug: "testimonials",
  },
  {
    title: "Jeffery Day",
    slug: "jeffery",
  },
  {
    title: "Get in touch",
    slug: "contact",
  },
];

const BREAKPOINTS = {
  xsmall: "575",
  small: "767",
  medium: "992",
  large: "1199",
};

export const BREAKPOINT_HEADER = "1040";

export default BREAKPOINTS;
